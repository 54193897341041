/*
 * b4st JS
 */

(function ($) {

	'use strict';

	$(document).ready(function() {

		// Comments

		$('.commentlist li').addClass('card mb-3');
		$('.comment-reply-link').addClass('btn btn-secondary');

		// Forms

		$('select, input[type=text], input[type=email], input[type=password], textarea').addClass('form-control');
		$('input[type=submit]').addClass('btn btn-primary');

		// Pagination fix for ellipsis

		$('.pagination .dots').addClass('page-link').parent().addClass('disabled');

		// Navigation Menu
		$('.navbar-toggler').on('click', function() {
			const $show = $(this).attr('aria-expanded');
			console.log($show);
			if ($show === 'false') {
				$('#site-navbar').css({'background-color': '#000000'});
			} else {
				$('#site-navbar').css({'background-color': 'transparent'});
			}
		});

		// Controls for Tabbed Section
		// Prev/Left Control
		$('#leftControl').on('click', function(e) {
			const $currentTab = $('#servicesTab li').children('a[class*="active"]');
			const $prevSibling = $currentTab.parent().prev();
			const $prevLink = $prevSibling.children();
			if ($prevSibling) {
				$prevLink.click();
			} else {
				e.preventDefault();
				$(this).css({
					'opacity': '0.6'
				});
			}
		});
		// Next/Right Control
		$('#rightControl').on('click', function (e) {
			const $currentTab = $('#servicesTab li').children('a[class*="active"]');
			const $nextSibling = $currentTab.parent().next();
			const $nextLink = $nextSibling.children();
			if ($nextSibling) {
				$nextLink.click();
			} else {
				e.preventDefault();
				$(this).css({
					'opacity': '0.6'
				});
			}
		});
		// Markers Control
		$('#servicesTab li').on('click', function() {
			const $p = $(this).index() + 1;
			console.log($('#tabMarkers div:nth-child(2)'));
			// $('#tabMarkers').children().removeClass('active');
			$('#tabMarkers div:nth-child('+$p+')')
				.addClass('active')
				.siblings()
				.removeClass('active');
		});

		// Gallery Functions
		$('.gallery-img-item').children().each(function(index) {
			$(this).attr('id', index);
		});
		const $galleryLength = $('.gallery-img-item').length - 1;
		function disableModalButtons(id) {
			id = parseInt(id);
			if (id === 0) {
				$('#galleryPrevButton').addClass('gallery-btn-disabled').attr('disabled', 'true');
			} else if (id === $galleryLength) {
				$('#galleryPrevButton').removeClass('gallery-btn-disabled').removeAttr('disabled');
				$('#galleryNextButton').addClass('gallery-btn-disabled').attr('disabled', 'true');
			} else {
				$('#galleryPrevButton').removeClass('gallery-btn-disabled').removeAttr('disabled');
				$('#galleryNextButton').removeClass('gallery-btn-disabled').removeAttr('disabled');
			}
		}
		// Project Gallery Modal function
		$('.gallery-img-item').on('click', function() {
			const $child = $(this).children();
			$('.modal-body').empty();
			$child.clone().appendTo('.modal-body');
			disableModalButtons($child.attr('id'));
			$('#gallery-modal').modal('show');
		});
		// Project Gallery Next Button
		$('#galleryNextButton').on('click', function() {
			let $currentID = $('.modal-body picture').attr('id');
			$currentID = parseInt($currentID) + 1;
			disableModalButtons($currentID);
			let $nextImg = $('.gallery-img-item > #' + String($currentID) + '');
			$('.modal-body').empty();
			$nextImg.clone().appendTo('.modal-body');
		});
		// Project Gallery Prev Button
		$('#galleryPrevButton').on('click', function () {
			let $currentID = $('.modal-body picture').attr('id');
			$currentID = parseInt($currentID) - 1;
			disableModalButtons($currentID);
			let $prevImg = $('.gallery-img-item > #' + String($currentID) + '');
			$('.modal-body').empty();
			$prevImg.clone().appendTo('.modal-body');
		});

	});

}(jQuery));
